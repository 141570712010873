<template>
  <li
    aria-haspopup="true"
    data-menu-toggle="click"
    class="menu-item menu-item-submenu menu-item-open-dropdown"
    v-bind:class="{ 'menu-item-active': hasActiveChildren(menu.MenuName) }"
  >
    <a href="#" class="menu-link menu-toggle">
      <i class="menu-icon" :class="menu.MenuIcon" style="color: #009ada"></i>
      <span class="menu-text"> {{ menu.MenuText }} </span>
    </a>
    <div class="menu-submenu menu-submenu-classic">
      <ul class="menu-subnav">
        <template v-for="(menuItem, index) in menu.submenu">
          <child-sub-menu-item
            v-if="!menuItem.submenu"
            :menu="menuItem"
            :menuId="index"
          >
          </child-sub-menu-item>
          <template v-else>
            <parent-child-menu :menu="menuItem" :menuId="index">
            </parent-child-menu>
          </template>
        </template>
      </ul>
    </div>
  </li>
</template>
<script>
import ChildSubMenuItem from "@/view/layout/header/ChildSubMenuItem.vue";
import ParentChildMenu from "@/view/layout/header/ParentChildMenu.vue";
export default {
  components: {
    ChildSubMenuItem,
    ParentChildMenu
  },
  props: {
    menu: {
      type: Object,
      required: true,
    },
    menuId: {
      required: true,
    },
  },
  mounted() {
    console.log("parent menu group=" + JSON.stringify(this.menu));
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
  },
};
</script>
<style lang="scss">
</style>