<template>
  <router-link
    :to="menu.MenuLink"
    v-slot="{ href, navigate, isActive, isExactActive }"
  >
    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item"
      :class="[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ]"
    >
      <a :href="href" class="menu-link" @click="navigate">
        <i class="menu-icon" :class="menu.MenuIcon" :style="menu.MenuStyle"></i>
        <span class="menu-text"> {{ menu.MenuText }} </span>
      </a>
    </li>
  </router-link>
</template>
<script>
export default {
  props: {
    menu: {
      type: Object,
      required: true,
    },
    menuId: {
      required: true,
    },
  },
  mounted() {
    console.log("menu item=" + JSON.stringify(this.menu));
  },
};
</script>
<style lang="scss">
</style>