<template>
  <div class="topbar">
    <v-overlay :value="LoadingFlag">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <!--begin::Search-->
    <!-- <KTQuickSearch></KTQuickSearch> -->
    <!--end::Search-->

    <!--begin: Notifications -->
    <div class="topbar-item">
      <v-btn icon color="red" @click.prevent="destroyUserSession">
        <v-icon>mdi-exit-to-app</v-icon>
      </v-btn>
    </div>
    <b-dropdown
      size="sm"
      variant="link"
      toggle-class="topbar-item text-decoration-none"
      no-caret
      right
      no-flip
    >
      <template v-slot:button-content>
        <div
          class="
            btn btn-icon btn-clean btn-dropdown btn-lg
            mr-1
            pulse pulse-primary
          "
        >
          <span class="svg-icon svg-icon-xl">
            <inline-svg src="media/svg/icons/Code/Compiling.svg" />
          </span>
          <span class="pulse-ring"></span>
        </div>
      </template>
      <b-dropdown-text tag="div" class="min-w-md-350px">
        <form>
          <KTDropdownNotification></KTDropdownNotification>
        </form>
      </b-dropdown-text>
    </b-dropdown>
    <!--end: Notifications -->

    <!--begin: Quick Actions -->
    <!-- <b-dropdown
      size="sm"
      variant="link"
      toggle-class="topbar-item text-decoration-none"
      no-caret
      right
      no-flip
    >
      <template v-slot:button-content>
        <div class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1">
          <span class="svg-icon svg-icon-xl">
            <inline-svg src="media/svg/icons/Media/Equalizer.svg" />
          </span>
        </div>
      </template>
      <b-dropdown-text tag="div" class="min-w-md-350px">
        <KTDropdownQuickAction></KTDropdownQuickAction>
      </b-dropdown-text>
    </b-dropdown> -->
    <!--end: Quick Actions -->

    <!--begin::User-->
    <KTQuickUser></KTQuickUser>
    <!--end::User-->
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import KTQuickUser from "@/view/layout/extras/offcanvas/QuickUser";
// import KTQuickSearch from "@/view/layout/extras/offcanvas/QuickSearch";
import KTDropdownNotification from "@/view/pages/erp/notifications/DropdownNotification.vue";
// import KTDropdownQuickAction from "@/view/layout/extras/dropdown/DropdownQuickAction.vue";
import { LOGOUT } from "@/core/services/store/auth.module";

export default {
  name: "Topbar",
  mixins: [common],
  components: {
    KTQuickUser,
    // KTQuickSearch,
    KTDropdownNotification,
    // KTDropdownQuickAction
  },
  data() {
    return {
      LoadingFlag: false,
      LogoutResult: "",
    };
  },
  watch: {
    LogoutResult: function () {
      console.log("watch LogoutResult");
      this.LoadingFlag = false;
    },
  },
  methods: {
    destroyUserSession() {
      console.log("destroyUserSession called");
      var token = this.$session.get("token");
      console.log("token=" + token);
      token = token == (null || undefined) ? 0 : token;
      localStorage.clear();
      this.$session.destroy();
      this.LoadingFlag = true;
      var url1 = "api/logout";
      var condition1 = {
        UserInterface: 1,
      };
      this.getLogoutResponse("LogoutResult", "", url1, condition1, token);
      this.$store.dispatch(LOGOUT).then(() => this.$router.push("/login"));
    },
  },
};
</script>

<style lang="scss">
.topbar {
  .dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  .dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>