<template>
  <li
    aria-haspopup="true"
    data-menu-toggle="hover"
    class="menu-item menu-item-submenu"
    v-bind:class="{ 'menu-item-active': hasActiveChildren(menu.MenuName) }"
  >
    <a href="#" class="menu-link menu-toggle">
      <i class="menu-icon " :class="menu.MenuIcon" :style="menu.MenuStyle"></i>
      <span class="menu-text"> {{ menu.MenuText }} </span>
      <i class="menu-arrow"></i>
    </a>
    <div class="menu-submenu menu-submenu-classic menu-submenu-right">
      <ul class="menu-subnav">
        <template v-for="(menuItem, index) in menu.submenu">
          <child-sub-menu-item :menu="menuItem" :menuId="index">
          </child-sub-menu-item>
        </template>
      </ul>
    </div>
  </li>
</template>
<script>
import ChildSubMenuItem from "@/view/layout/header/ChildSubMenuItem.vue";
export default {
  components: {
    ChildSubMenuItem,
  },
  props: {
    menu: {
      type: Object,
      required: true,
    },
    menuId: {
      required: true,
    },
  },
  mounted() {
    console.log("parent child menu=" + JSON.stringify(this.menu));
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
  },
};
</script>
<style lang="scss">
</style>