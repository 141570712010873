<template>
    <ul class="menu-nav">

      <template v-for="(headerMenuItem, index) in headerMenuItems">
        <parent-menu-item
          v-if="!headerMenuItem.submenu"
          :menu="headerMenuItem"
          :menuId="index"
        >
        </parent-menu-item>
        <template v-else>
          <parent-menu-group :menu="headerMenuItem" :menuId="index">
          </parent-menu-group>
        </template>
      </template>

      <!-- <li
      aria-haspopup="true"
      data-menu-toggle="click"
      class="menu-item menu-item-submenu menu-item-open-dropdown"
      v-bind:class="{ 'menu-item-active': hasActiveChildren('/custom') }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon fa fa-users" style="color: #009ada"></i>
        <span class="menu-text"> Members </span>
      </a>
      <div class="menu-submenu menu-submenu-classic">
        <ul class="menu-subnav">
          <router-link
            to="/members/transfer"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item menu-item-submenu"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i
                  class="menu-icon fa fa-exchange-alt"
                  style="color: #009ada"
                ></i>
                <span class="menu-text"> Transfer </span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li> -->

    </ul>
</template>

<script>
import ParentMenuItem from "@/view/layout/header/ParentMenuItem.vue";
import ParentMenuGroup from "@/view/layout/header/ParentMenuGroup.vue";
import common from "@/view/Common.vue";
export default {
  name: "KTMenu",
  mixins: [common],
  components: {
    ParentMenuItem,
    ParentMenuGroup,
  },
  data() {
    return {
      headerMenuId: "",
      headerMenuItems: [],

      menuItems: [
        {
          MenuId: 1,
          MenuName: "Member Transfer",
          MenuText: "1 My Member Transfer",
          MenuIcon: "fa fa-user",
          MenuLink: "/members/transfer",
        },
        {
          MenuId: 2,
          MenuName: "Staff Menu",
          MenuText: "2 My Staff First Menu",
          MenuIcon: "fa fa-user",
          MenuLink: "/staff/list",
          submenu: [
            {
              MenuId: 1,
              MenuName: "Member Transfer",
              MenuText: "3 Staff Transfer",
              MenuIcon: "fa fa-user",
              MenuLink: "/members/transfer",
              submenu: [
                {
                  MenuId: 1,
                  MenuName: "Member Transfer",
                  MenuText: "4 Staff List",
                  MenuIcon: "fa fa-user",
                  MenuLink: "/members/transfer",
                },
                {
                  MenuId: 2,
                  MenuName: "Staff Menu",
                  MenuText: "5 Staff Create",
                  MenuIcon: "fa fa-user",
                  MenuLink: "/staff/list",
                },
              ],
            },
            {
              MenuId: 2,
              MenuName: "Staff Menu",
              MenuText: "6 Staff Second Menu",
              MenuIcon: "fa fa-user",
              MenuLink: "/staff/list",
            },
          ],
        },
      ],
    };
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
    refreshPageData() {
      console.log("refreshPageData called");
      this.getHeaderMenuLists("headerMenuId", "headerMenuItems");
    },
    getHeaderMenuLists(source, destination) {
      console.log("getHeaderMenuLists called");
      console.log(
        "source=" +
          source +
          ", destination=" +
          destination
      );
      if (source != "" && destination != "") {
        this.LoadingFlag = true;
        var selectbox1_source = source;
        var selectbox1_destination = destination;
        var selectbox1_url = "api/menu/lists";
        var selectbox1_conditions_array = {
          UserInterface: 1,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
